/*
=========================================================
*/

// Material Kit 2 React pages
import ContactUs from "pages/LandingPages/ContactUs";

export default function ContactUsPage() {
  return <ContactUs />;
}
