/*
=========================================================
*/

// Material Kit 2 React pages
import Author from "pages/LandingPages/Author";

export default function AuthorPage() {
  return <Author />;
}
