/*
=========================================================
*/

// Material Kit 2 React pages
import AboutUs from "pages/LandingPages/AboutUs";

export default function AboutUsPage() {
  return <AboutUs />;
}
