/*
=========================================================
*/

// Material Kit 2 React pages
import SignIn from "pages/LandingPages/SignIn";

export default function SignInPage() {
  return <SignIn />;
}
