/*
=========================================================
*/

// Material Kit 2 React pages
import Presentation from "pages/Presentation";

export default function PresentationPage() {
  return <Presentation />;
}
